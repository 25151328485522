@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: "Infiniti Primary Regular", Arial, sans-serif;
$boldFontFamily: "Infiniti Primary Bold", Arial, sans-serif;
$boldFontWeight: 700;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #000000;
$linkColor: #666666;
$labelColor: #ccc;
$formColor: #000000;
$utilColor: #000000;
$priceColor: #000000;
$lightColor: #9999A0;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #333333;
$titleSecondaryColor: #ffffff;
$subtitleColor: #333333;
$subtitleSecondaryColor: #ffffff;
$successColor: #000000;
$warningColor: #000000;
$errorColor: #000000;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #000000;
$colorBtnAlpha: #ffffff;
$bgBtnBeta: #333333;
$colorBtnBeta: #ffffff;
$colorBtnOutlineAlpha: #555555;
$colorBtnOutlineBeta: #000000;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$garageBackground: #ffffff;
$garageTextColor: #525252;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin:#9999A0;
$colorTopbarLogin: #000000;

$progressBarTitleBG: #000000;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #000000;
$progressBarCloseButtonBG: #000000 ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #000000;
$paymentCalculatorClosedColor: #ffffff;
$paymentCalculatorPanelBG: #000000;
$paymentCalculatorTermWrapperBG: #595959;
$paymentCalculatorTermWrapperColor: #000000;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #9999A0;
$paymentCalculatorTermColor: #000000;

$calculatorTabsColor: #595959;
$calculatorTabsTextColor: #ffffff;
$calculatorCloseTabs: #595959;
$calculatorCloseTabsColor: #ffffff;
$calculatorMainColor: #ffffff;
$calculatorButton: #9999A0;

// ========
// Styles
// ========
.widget-sr{
    @include clearfix;
    width: 100%;

    &.makes__infiniti{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .message-handler.warning{
            background-color: #000000;
        }
        // **********************
        // Buttons
        // **********************
        .sr-button-1,
        .sr-button-2{
            box-shadow: none !important;
            transition: all 0.2s ease-in-out !important;
            &:hover{
                opacity: 1 !important;
            }
        }
        .sr-button-2{
            background: transparent !important;
            color: #000000 !important;
            border: 2px solid #000000 !important;
            &:hover{
                color: #ffffff !important;
                background: #000000 !important;
            }
        }
        .sr-button-1{
            background: #000000 !important;
            color: #ffffff !important;
            border: 2px solid #000000 !important;
            &:hover{
                color: #000000 !important;
                background: transparent !important;
            }
        }
        // Login + transaction bar
        .widget-login .dropdown-content ul li a, .widget-register .dropdown-content ul li a{
            color: #000000;
            border-color: #000000;
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate{
            border-bottom-color: #000000;
        }
        .TransactionSummaryUnit--single .validFlag:before{
            background-image: -webkit-linear-gradient(304deg,transparent 50%,#000000 55%)
        }
        .TransactionProgressBar--progression{
            background-color: #000000;
        }
        .widget-profile-popupElement form#profileForm [type=checkbox]:checked+label:before,
        .widget-profile-popupElement form#profileForm input[type=radio]:checked+label:before{
            border-color: #000000 !important;
        }
        .widget-garage .vehicle-wrapper-info .vehicle-transaction{
            color: #000000;
        }
        // Listings
        .listing-tile .listing-tile-image .car-tagline{
            color: #ffffff;
        }
        .listing-used-bodystyle{
            color: #777777;
            &.selected{
                border: 2px solid #000000!important;
                &:before{
                    color: #000000;
                }
            }
        }
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button.selected{
            color: #000000;
            border: 2px solid #000000;
        }
        // VDP
        .vehicleBanner-photos .vehicleBanner-photo-button{
            background: #ffffff;
            &:hover{
                background: rgba(255,255,255,0.7);
            }
            icon{
                color: #000000;
            }
        }
        
        // Build and buy
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single.selected{
            color: #000000;
        }
        .selection-year-bloc{
            border-radius: 0;
            &:hover{
                border-color: #9999A0;
                .selection-year-bloc__selectButton{
                    background-color: #9999A0;
                }
            }
        }
        .bloc-options__single{
            border-radius: 0;
        }
        .bloc-options__single--footer .option-choose-cta{
            border-radius: 0;
        }
        .style-color__button.selected{
            border-color: #000000;
        }
        .checkout__section-delivery label .delivery-options-image{
            display: none;
        }
        .Appointment__content .appointment__steps--single.is-validated .title_wrapper>icon,
        .confirm_financing--icon icon,
        .confirm_financing--title,
        .section-checkout-header__progressBar-stage.validated .picto-showroom:first-child,
        .confirm_deposit--icon icon,
        .confirm_deposit--title
        {
            color: #000000;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border-color: #000000;
        }
        .section-checkout-header__progressBar-stage.validated.selected .picto-showroom:first-child{
            color: #ffffff;
        }
        // Calculatrice
        .payment-calculator-wrapper{
            .is-util{
                color: #ffffff;
            }
            .is-light{
                color: #ffffff;
            }
            .Select-arrow{
                border-top-color: #333333;
            }
            .Select .Select-arrow-zone:hover>.Select-arrow{
                border-top-color: #000000;
            }
            .calculator-done{
                .sr-button-1{
                    color: #000000 !important;
                    background: #ffffff !important;
                }
            }
            .InputText .input-control{
                border-radius: 0;
            }
            .paymentCalculator__buttons{
                #calculator-toggle-button.sr-button-1{
                    background: #ffffff !important;
                    color: #000000 !important;
                    border: none !important;
                    &:hover{
                        background: #ffffff !important;
                        color: #000000 !important;
                    }
                }
            }
            .sr-paymentCalculator__calculatorTerm--background.selected{
                .term{
                    background: #595959;
                }
                .is-light{
                    color: #9999A0 !important;
                }
            } 
            .calculator-tradeIn .input-format-currency{
                border-radius: 0 !important;
            }
            .sr-calculator-tabs li.current{
                border-color: #ffffff;
            }
        }
        .sr-paymentCalculator-Panelbackground{
            .is-util{
                color: #ffffff;
            }
            .calculator-term{
                border-radius: 0;
                .is-util{
                    color: #000000;
                }
            }
        }
        .sr-paymentCalculator-dropdown .is-focused .Select-control,
        .sr-paymentCalculator-dropdown .Select-control,
        .sr-paymentCalculator-dropdown .Select-option{
            background-color: #595959 !important;
            border-radius: 0 !important;

        }
        .InputNumber--minus,
        .InputNumber--add{
            border-radius: 0;
        }
        
        // Forms
        .field-error.InputText .input-control{
            border-color: #000000;
        }

        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #000000!important;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#000000 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #000000 !important;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border: 1px solid #000000 !important;
            .icon,.icon-Crochet{
                color: #000000 !important;
            }
        }
        .confirm_deposit--title,
        .confirm_financing--title{
            color: #000000;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #000000;
            }
        }
        .Appointment__content .appointment__steps--single.is-validated {
            .icon-calendar{
                color: #000000 !important;
            }
        }
        .doneLabel {
            color: #000000 !important;
            .icon-Crochet{
                color: #000000 !important;
            }
        }

        .message-handler{
            &.warning,
            &.success,{
                background-color: #000000 !important;
            }
        }
        .has-success-background{
            background-color: #000000 !important;
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}
@include breakpoint(mobile){
    .widget-sr{
        &.makes__infiniti{
            .LoginBanner .LoginBannerWrapper{
                min-height: 33px;
                max-height: 35px;
                .login-content{
                    display: flex;
                    #button-login.btn-login{
                        margin-left: 10px;
                    }
                    #button-register.btn-register{
                        margin-left: 10px;
                    }
                }
            }
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
            .vdp-used-form{
                padding: 0 0 30px;
            }
            .listing-used .listing-used-inner-content .listing-used-header-refine,
            .listing-used-payment .listing-used-set-budget{
                margin: 0 15px;
            }
            .listing-used-results{
                margin: 15px;
            }
            .listing-tiles-wrapper{
                .listing-tile-link{
                    width: 100%;
                    margin: 0 0 60px;
                    padding: 0 15px;
    
                }
                .listing-tile-note{
                    padding: 0 15px;
                }
            }
        }
    }
}
@include breakpoint(mobileTablet){
    .widget-sr{
        &.makes__infiniti{
            .LoginBanner{
                padding: 0 !important;
                .TransactionSummary--wrapper--title{
                    span{
                        font-size: 10px !important;
                    }
                }
                .login-content{
                    padding: 0 !important;
                }
                .label,
                .btn-login .label{
                    font-size: 10px !important;
                }
            }
            //Fix Boutons Filtres Mobiles
            .listing-used .listing-used-inner-content .listing-used-header-refine{
                button{
                    width: 100%;
                    padding: 0 20px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
            .listing-used-payment .listing-used-set-budget{
                button{
                    width: 100%;
                    padding: 0 20px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
        }
    }
}
@include breakpoint(tablet){
    .widget-sr{
        &.makes__infiniti{
            .purchase-methods-cash .obligationsTotal .price-title icon{
                display: none;
            }
            .paymentCalculator__wrapper{
                .paymentCalculator__paymentsInfos{
                    min-width: 500px;
                }
            }
        }
    }
}
@include breakpoint(tabletPortrait){
    .widget-sr{
        &.makes__infiniti{
            .checkout__content-left-section{
                width:100%;
                float: unset;
                padding-right: 20px;
            }
            .checkout__content-right-section{
                width: 100%;
                float: unset;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__infiniti{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}